import * as React from "react"
import BlogLayout from "../components/word/BlogLayout"
import WordList from "../components/word/WordList"
import { ChevronRightIcon } from "@chakra-ui/icons"
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react"

export default function MonthTemplate({ pageContext }: any): JSX.Element {
  let { month, year, members } = pageContext
  month = month.toString().padStart(2, "0")
  return (
    <BlogLayout pageTitle={`${year}-${month}`}>
      <Breadcrumb
        spacing="8px"
        separator={<ChevronRightIcon color="gray.500" />}
        pt={6}
        pl={4}
        pb={1}
      >
        <BreadcrumbItem>
          <BreadcrumbLink href="/word">word</BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem>
          <BreadcrumbLink href={`/word/${year}`}>{year}</BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink href={"#"}>{month}</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <WordList nodes={members.data.allMdx.nodes} />
    </BlogLayout>
  )
}
